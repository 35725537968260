var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card-group', {
    staticClass: "card-columns",
    attrs: {
      "columns": ""
    }
  }, [_c('b-card', {
    attrs: {
      "header": "Line Chart"
    }
  }, [_c('div', {
    staticClass: "chart-wrapper"
  }, [_c('line-example', {
    attrs: {
      "chartId": "chart-line-01"
    }
  })], 1)]), _c('b-card', {
    attrs: {
      "header": "Bar Chart"
    }
  }, [_c('div', {
    staticClass: "chart-wrapper"
  }, [_c('bar-example', {
    attrs: {
      "chartId": "chart-bar-01"
    }
  })], 1)]), _c('b-card', {
    attrs: {
      "header": "Doughnut Chart"
    }
  }, [_c('div', {
    staticClass: "chart-wrapper"
  }, [_c('doughnut-example', {
    attrs: {
      "chartId": "chart-doughnut-01"
    }
  })], 1)]), _c('b-card', {
    attrs: {
      "header": "Radar Chart"
    }
  }, [_c('div', {
    staticClass: "chart-wrapper"
  }, [_c('radar-example', {
    attrs: {
      "chartId": "chart-radar-01"
    }
  })], 1)]), _c('b-card', {
    attrs: {
      "header": "Pie Chart"
    }
  }, [_c('div', {
    staticClass: "chart-wrapper"
  }, [_c('pie-example', {
    attrs: {
      "chartId": "chart-pie-01"
    }
  })], 1)]), _c('b-card', {
    attrs: {
      "header": "Polar Area Chart"
    }
  }, [_c('div', {
    staticClass: "chart-wrapper"
  }, [_c('polar-area-example', {
    attrs: {
      "chartId": "chart-polar-area-01"
    }
  })], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }